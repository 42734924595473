/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://ds7ufjoizzbxrefpozsqycv7su.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_cognito_identity_pool_id": "ap-southeast-1:10555350-ce3f-4f6b-b278-6aee825bd8fd",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_eucMWE9kR",
    "aws_user_pools_web_client_id": "7nc0ksngp21tdqihbisc6c868f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "GENDER",
        "BIRTHDATE",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_bots": "enable",
    "aws_bots_config": [
        {
            "name": "OrderFlowers_staging",
            "alias": "$LATEST",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "myducbucket192729-staging",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
